import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "posts single-post-container"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_post_tile = _resolveComponent("post-tile")!
  const _component_post_tags = _resolveComponent("post-tags")!
  const _component_loader = _resolveComponent("loader")!

  return (!_ctx.isLoading)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_post_tile, {
          post: _ctx.postContent,
          "show-link": false
        }, null, 8, ["post"]),
        _createVNode(_component_post_tags, { post: _ctx.postContent }, null, 8, ["post"])
      ]))
    : (_openBlock(), _createBlock(_component_loader, { key: 1 }))
}