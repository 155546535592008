
import { defineComponent } from "vue";
import PostTile from "@/components/Blog/PostTile.vue";
import { base64EncryptPostId } from "@/config/blog";
import { getBlogPost } from "@/utils/blog";
import PostTags from "@/components/Blog/PostTags.vue";
import Loader from "@/components/common/Loader.vue";

export default defineComponent({
  components: { PostTile, PostTags, Loader },

  data() {
    return { postContent: {}, isLoading: true };
  },

  methods: {
    getPostContent(id: string) {
      getBlogPost(id).then((post) => {
        this.postContent = post?.[0];
        this.isLoading = false;
      });
    },
  },

  mounted() {
    let { id } = this.$route.params;
    if (base64EncryptPostId) id = atob(`${id}`);
    if (Array.isArray(id)) id = id.join(",");

    this.getPostContent(id);
  },
});
