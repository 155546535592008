<template>
  <div class="flex flex-wrap mt-5 justify-center">
    <router-link
      :to="{ name: 'TagPosts', params: { tag } }"
      v-for="tag in post.tags"
      class="post-tag"
      :data-post-tag="tag"
      :key="tag"
      :title="`Post Tag: ${tag}`"
    >
      #<span v-text="tag"></span>
    </router-link>
  </div>
</template>

<script>
import { defineComponent } from "@vue/runtime-core";

export default defineComponent({
  props: { post: { type: Object, required: true } },
});
</script>
